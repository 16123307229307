html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;

  * {
    font-family: "Work Sans", sans-serif;
  }
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
